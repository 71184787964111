import React, { useState, useEffect } from 'react';
import blue from '../images/session-card-shape-blue.png'
import green from '../images/session-card-shape-green.png'
import orange from '../images/session-card-shape-orange.png'
import purple from '../images/session-card-shape-purple.png'
import yellow from '../images/session-card-shape-yellow.png'
import red from '../images/session-card-shape-red.png'
import multicolor from '../images/multicolor-hline.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
import moment from 'moment/moment';
import gmapicon from '../images/gmap-icon.png'
import { useDispatch, useSelector } from 'react-redux';
import { resetData, setActiveTab, setAllSessions, setAllSpeakers, setAllSponsers, setSearch, setSelectedCategory, setSelectedLanguage, setSelectedSpeaker, setSelectedVenue } from '../redux/reducers/sessionReducers';
import { CalendarArrowDown, CalendarArrowUp, SortAsc } from 'lucide-react';
import { Link } from 'react-router-dom';
import SessionFavModal from './SessionFavModal';

const SessionOldList = () => {

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false);

  const allSessions = useSelector((state)=> state.sessions.allSessions)
  const allSpeakers = useSelector((state)=> state.sessions.allSpeakers)
  const allSponsers = useSelector((state)=> state.sessions.allSponsers)

  const selectedSpeaker = useSelector((state)=> state.sessions.selectedSpeaker)
  const selectedVenue = useSelector((state)=> state.sessions.selectedVenue)
  const activeTab = useSelector((state)=> state.sessions.activeTab)
  const search = useSelector((state)=> state.sessions.search)
  const selectedCategory = useSelector((state)=> state.sessions.selectedCategory)
  const selectedLanguage = useSelector((state)=> state.sessions.selectedLanguage)

  const [sessions, setSessions] = useState([]);
  const [venues, setVenues] = useState([]);

  const [sort, setSort] = useState('asc');

  const [isLoading, setIsLoading] = useState(true);

  const [speakerFilter, setSpeakerFilter] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
        
  const getSessionData = async () => {
    dispatch(resetData())
    try{
      const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/sessions.json");
      const text = await response.text();
      const data = await JSON.parse(text);
      dispatch(setAllSessions(data))
      const sortData = sortSessionData(data);
      setSessions(sortData);
      setIsLoading(false);
    }catch(error){
      console.log("Error fetching data:", error.message);
      setIsLoading(false);
    }
  };

  const sortSessionData = (sessions) => {

    return [...sessions].sort((a, b) => {
      const dateA = a.date.split('T')[0];
      const dateB = b.date.split('T')[0];
      
      let dateComparison = new Date(dateA) - new Date(dateB);
      
      if (dateComparison === 0) {
        const timeA = convertTimeToMinutes(a.time_from);
        const timeB = convertTimeToMinutes(b.time_from);
        dateComparison = timeA - timeB;
      }
      
      return sort === 'desc' ? -dateComparison : dateComparison;

    });
  };
  
  const convertTimeToMinutes = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return (hours * 60) + minutes;
  };
  
  const getSpeakerData = async () => {
    try{
      const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/speakers.json");
      const text = await response.text();
      const data = JSON.parse(text);
      dispatch(setAllSpeakers(data))
    }catch(error){
      console.error("Error fetching data:", error);
    }
  };

  const getVenueData = async () => {
    try{
      const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/venue.json");
      const text = await response.text();
      const data = JSON.parse(text);
      setVenues(data);
    }catch(error){
      console.error("Error fetching data:", error);
    }
  };

  const getSessionSponsers = async () => {
    try{
      const session_sponsors = await fetch("https://cf-cdn.keralaliteraturefestival.com/session_sponsor.json");
      const sponsors = await fetch("https://cf-cdn.keralaliteraturefestival.com/sponsor.json");
      const sessionSponsorsText = await session_sponsors.text();
      const sponsorsText = await sponsors.text();
      const sessionSponsorsData = JSON.parse(sessionSponsorsText);
      const spornsorsData = JSON.parse(sponsorsText);
      const sessionData = sessionSponsorsData?.map(sesspo => {
        const sessionSponsor = spornsorsData?.find(sponsor => sesspo.sponsorId === sponsor.id);
        return {
          ...sesspo,
          sponsors: sessionSponsor?.name || ''
        };
      })
      dispatch(setAllSponsers(sessionData))
    }catch(error){
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSessionData();
  }, []);

  useEffect(() => {
    getSpeakerData();
    getVenueData()
    getSessionSponsers()
  }, []);


  const findUniqueDates = () => {
    const sessionDates = allSessions
    .filter(session => session.date !== null)
    .map(session => moment(session.date).format('YYYY-MM-DD'));
    const uniqueDates = sessionDates.filter((date, index) => sessionDates.indexOf(date) === index);
    const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
    const formattedDates = sortedDates.map(date => moment(date).format('MMM D'));
    return [...formattedDates, 'All'];
  }; 

  const uniqueDates = findUniqueDates()

  const findUniqueCategories = () => {
    const sessionCategories = allSessions
    .filter(session => session.category !== null)
    .map(session => session.category);
    const uniqueCategories = sessionCategories.filter((category, index) => sessionCategories.findIndex(cat => cat.id === category.id) === index);
    return uniqueCategories;
  };

  const uniqueCategories = findUniqueCategories()

  const handleSpeakerFilter = (id) => {
    dispatch(setSelectedSpeaker(id))
    const speaker = allSpeakers.find((speaker) => speaker.id === id);
    if(speaker){
      setSpeakerFilter(speaker.heading);
    }else{
      setSpeakerFilter('');
    }
    setIsDropdownOpen(false);
  };

  const handleVenueFilter = (e) => {
    dispatch(setSelectedVenue(e.target.value))
  };

  const handleTabClick = (formattedDate) => {
    dispatch(setActiveTab(formattedDate))
  };

  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value))
  };

  const handleCategory = (e) => {
    dispatch(setSelectedCategory(e.target.value))
  };

  useEffect(() => {
    let filteredData = allSessions
    if(selectedSpeaker){
      filteredData = filteredData?.filter((session) =>
        session?.session_speackers?.some((element) => element.speaker.id === selectedSpeaker)
      );
    }
    if(search){
      filteredData = filteredData?.filter((session) =>
        session?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }
    if(selectedVenue){
      filteredData = filteredData?.filter((session) =>
        session?.venue?.id === Number(selectedVenue)
      );
    }
    if(selectedCategory){
      filteredData = filteredData?.filter((session) =>
        session?.category?.id === Number(selectedCategory)
      )
    }
    if(selectedLanguage){
      filteredData = filteredData?.filter((session) =>
        session?.language === selectedLanguage
      )
    }
    if(activeTab !== 'All'){
      filteredData = filteredData?.filter((session) =>
        moment(session?.date).format('MMM D') === activeTab
      )
    }
    const sortData = sortSessionData(filteredData);
    setSessions(sortData)
  }, [selectedSpeaker, search, selectedVenue, activeTab, selectedCategory, selectedLanguage, sort]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (isLoading) {
    return <div>Loading sessions...</div>;
  }
  
  // Colors array for alternating card colors
  const cardColors = ['yellow', 'blue', 'red', 'green'];
  
  // Function to format session date and time
  const formatSessionDateTime = (session, index) => {
    if (!session.date || !session.time_from || !session.time_to) return null;
    
    const day = moment(session.date).format('MMM DD');
    const color = cardColors[index % cardColors.length];
    
    const timeFrom = moment(session.time_from, 'HH:mm:ss').format('h:mm A');
    const timeTo = moment(session.time_to, 'HH:mm:ss').format('h:mm A');
    
    return {
      day,
      color,
      timeRange: `${timeFrom}-${timeTo}`
    };
  };

  return (
    <div className='bg-white'>
        <Navbar handleOpen={handleOpen} />

        <div className="innerbanner container-fluid session-banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{position:'relative'}}>
                        <span className="logo-strips"></span> <h2>Programme</h2> 
                        {/* <a 
                        href="#" 
                        className={`icon-filter_list phoneonly btn-filter ${isFilterVisible ? 'active' : ''}`} 
                        onClick={handleMobileFilterToggle}
                      ></a> */}
                    </div>
                </div>
            </div>
        </div>			

        <div className="sessions-tabs container">
          <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <ul className="nav nav-tabs">
                  {uniqueDates.map((formattedDate, index) => {
                    const tabColors = ['#f9cd26', '#e13231', 'green', '#4aa6df', '#ec842c'];
                    return (
                      <li key={formattedDate} className="nav-item">
                        <a
                        className={`nav-link ${activeTab === formattedDate ? 'active' : ''}`}
                        onClick={() => handleTabClick(formattedDate)}
                        href="#"
                        style={{
                          color: activeTab === formattedDate 
                            ? tabColors[index % tabColors.length] 
                            : ''
                        }}
                        >
                          {formattedDate}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
          </div>
        </div>

        <div className={`container filter-search-wrpr active`}>
          <div className="row">

            <div className="col-md-12 filter-wrpr session-filter-wrpr">
              <div className="row">
                  <div className="col-md-2 mb-3">
                      <label>Venue</label>
                      <select 
                      className="form-control form-control-sm"
                      value={selectedVenue || ''}
                      onChange={handleVenueFilter}
                      >
                        <div className="dropdown-search-wrapper" />
                          <option value="">All Venues</option>
                          {venues.map((venue) => (
                            <option key={venue.id} value={venue.id}>
                              {venue?.name} {venue?.number && `- (${venue?.number})`}
                            </option>
                          ))}
                      </select>
                  </div>

                  <div className="col-md-2 mb-3">
                    <label>Event Category</label>
                    <div className="dropdown">
                      <select 
                      className="form-control form-control-sm select2"
                      value={selectedCategory || ''}
                      onChange={handleCategory}
                      >
                        <option value="">All Categories</option>
                        {uniqueCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-2 mb-3">
                    <label> Program Language</label>
                    <div className="dropdown">
                      <select 
                      className="form-control form-control-sm select2"
                      value={selectedLanguage || ''}
                      onChange={(e) => dispatch(setSelectedLanguage(e.target.value))}
                      >
                        <option value="">All Languages</option>
                        {["Malayalam", "English", "Children"].map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-2 mb-3">
                    <label>Speakers</label>
                    <div className="dropdown">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Search speakers..."
                      value={speakerFilter}
                      onChange={(e) => setSpeakerFilter(e.target.value)}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpen(true);
                      }}
                    />
                      {isDropdownOpen && (
                        <div
                        className="dropdown-menu show w-100"
                        style={{
                          maxHeight: '300px',
                          overflowY: 'auto',
                          padding: '10px',
                          display: 'block',
                          position: 'absolute',
                          zIndex: 1000
                        }}
                        >
                          <button className={`dropdown-item hover-blue ${selectedSpeaker === '' ? 'active' : ''}`} onClick={() => handleSpeakerFilter('')}>
                            All Speakers
                          </button>

                          {allSpeakers.filter((speaker) =>
                            speaker.heading.toLowerCase().includes(speakerFilter.toLowerCase())
                          ).map((speaker) => (
                              <button
                              key={speaker.id}
                              className={`dropdown-item hover-blue ${selectedSpeaker === speaker.heading ? 'active' : ''}`}
                              onClick={() => {
                                handleSpeakerFilter(speaker.id);
                              }}
                              >
                                {speaker.heading}
                              </button>
                            ))
                          }

                          {allSpeakers?.filter((speaker) =>
                            speaker?.heading?.toLowerCase().includes(speakerFilter.toLowerCase())
                          ).length === 0 && (
                            <div className="dropdown-item text-muted">
                              No speakers found
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>


                  <div className="col-md-4 mb-3">
                    <div className="search-wrpr">
                      <svg className="search-icon-svg" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.164 10.133L16 14.97 14.969 16l-4.836-4.836a6.225 6.225 0 01-3.875 1.352 6.24 6.24 0 01-4.427-1.832A6.272 6.272 0 010 6.258 6.24 6.24 0 011.831 1.83 6.272 6.272 0 016.258 0c1.67 0 3.235.658 4.426 1.831a6.272 6.272 0 011.832 4.427c0 1.422-.48 2.773-1.352 3.875zM6.258 1.458c-1.28 0-2.49.498-3.396 1.404-1.866 1.867-1.866 4.925 0 6.791a4.774 4.774 0 003.396 1.405c1.28 0 2.489-.498 3.395-1.405 1.867-1.866 1.867-4.924 0-6.79a4.774 4.774 0 00-3.395-1.405z"></path></svg>
                      
                      <div className="input-group">
                        <input 
                        type="text"
                        className="form-control form-control-sm" 
                        placeholder="Search program..."
                        value={search}
                        onChange={handleSearch}
                        />
                          <div className="input-group-append">
                            <button className="input-group-text btn-search-events">Search</button>
                          </div>
                        </div>
                      
                    </div>
                  </div>

              </div>
            </div>

            <div style={{width:'100%', display:'flex', justifyContent:'end', alignItems:'center', marginTop:'15px'}}>
              <button style={{backgroundColor:'transparent', border: 'none'}} onClick={() => setSort(sort === 'asc' ? 'desc' : 'asc')}>
                {
                  sort == 'desc' ?
                  <CalendarArrowDown size={18} />:
                  <CalendarArrowUp size={18} />
                }
                <span style={{marginLeft: '5px'}}>
                  {
                    sort == 'desc' ? 'Least Recent' : 'Most Recent'
                  }
                </span>
              </button>
            </div>

            <div className="col-md-12">
              <img className="multicolorline" style={{marginTop: 0}} src={multicolor}  />
            </div>

          </div>
        </div>

        <div className="container search-cntnr">
          <div className="row">
              <div className="col-md-12 pb-5">
                  
              </div>
          </div>
        </div>
        

        <div className="sessions-wrpr bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-12  pb-5 ">
                        <div className="tab-content">
                            <div id="january23" className="tab-pane active">
                            
                                <div className="sessions-slide row aos-init swiper-container-initialized swiper-container-horizontal">
                                    {sessions.map((session, index) => {
                                        const venue = venues.find(venue => venue.id === session?.venue?.id);
                                        return(
                                          <div className="col-md-3 text-center">
                                              <div className="card session-card">
                                                  <div className="card-header scard-header">
                                                    <div className="symbol-venue-fav-wrpr">
                                                        <div className="symbol-venue">
                                                            <img 
                                                            src={[blue, red, green, orange, purple, yellow][index % 6]} 
                                                            alt="Session card background"
                                                            />
                                                            <div className="venue">
                                                                {venue?.number &&
                                                                    <div 
                                                                    style={{
                                                                      backgroundColor: cardColors[index % cardColors.length],
                                                                      color: cardColors[index % cardColors.length] === 'yellow' ? 'black' : 'white',
                                                                      display: 'flex',
                                                                      justifyContent: 'center',
                                                                      alignItems: 'center',
                                                                      fontWeight: 'normal',
                                                                      height: '20px',
                                                                      width: '20px',
                                                                      borderRadius: '50%',
                                                                      marginRight: '10px',
                                                                    }}>
                                                                      {venue?.number}
                                                                    </div>
                                                                }
                                                                <div className="venue-eng">{venue?.name}</div>
                                                                <a href={venue?.place} class="icon-location-custom" target="_blank" title="Venue Location"><img src={gmapicon} /></a>
                                                            </div>
                                                        </div>
                                                        <div class="fav-wrpr">
                                                        <SessionFavModal/>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  
                                                  <div className="card-body">
                                                      <div className="date-time">
                                                        {(() => {
                                                          const dateTime = formatSessionDateTime(session, index);
                                                          return dateTime && (
                                                            <>
                                                            <div className={`date ${dateTime.color}`}>
                                                                <span>
                                                                  {dateTime.day}
                                                                </span>
                                                              </div>
                                                              <div className="time">
                                                                <i className="icon-time1"></i>
                                                                <span>{dateTime.timeRange}</span>
                                                              </div>
                                                            </>
                                                          );
                                                        })()}
                                                      </div>

                                                      <div className="scard-heading">
                                                          <h2>{session?.name}</h2>
                                                      </div>

                                                      {
                                                        session?.category?.name &&
                                                        <div className="session-type">
                                                          <div className="session-type-label">{session?.category?.name}</div>
                                                        </div>
                                                      }
                                                  
                                                      {session?.session_speackers?.length > 0 && (
                                                        <div className="session-members speakers">
                                                          <ul>
                                                            {session?.session_speackers
                                                              ?.slice()
                                                              ?.sort((a, b) => a.id - b.id)
                                                              ?.map((element) => {
                                                                const speaker = allSpeakers?.find(speaker => speaker.id === element.speaker.id);
                                                                if (!speaker) return null;                                                                                                
                                                                return (
                                                                  <li key={speaker.id} className="mb-1">
                                                                    <Link
                                                                      to={`/speakerview/${element.speaker.id}/${speaker.heading}`}
                                                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                      className="cursor-pointer decoration-none"
                                                                    >
                                                                      {speaker.heading}
                                                                    </Link>
                                                                  </li>
                                                                );
                                                              })}
                                                          </ul>
                                                        </div>
                                                      )}

                                                      {session?.session_moderators?.length > 0 &&
                                                        <div className="session-members modarators">
                                                          <ul>
                                                            {session?.session_moderators?.map((element, idx) => {
                                                              const speaker = allSpeakers?.find(speaker => speaker.id === element.speaker.id);
                                                              if (!speaker) return null;
                                                              return (
                                                                <li key={idx} className="mb-1">
                                                                  <Link
                                                                    to={`/speakerview/${element.speaker.id}/${speaker.heading}`}
                                                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                  >
                                                                    {speaker?.heading}
                                                                  </Link>
                                                                </li>
                                                              );
                                                            })}
                                                          </ul>
                                                        </div>
                                                      }
    


                                                      {session?.speakers?.length > 0 &&
                                                        <div className="session-members storyteller">
                                                          <ul>
                                                            {session?.speakers?.map((element, idx) => {
                                                              // const speaker = allSpeakers?.find(speaker => speaker.id === element.speaker.id);
                                                              // if (!speaker) return null;
                                                              return (
                                                                <li key={idx} className="mb-1">
                                                                  <Link
                                                                    to={`/speakerview/${element?.id}/${element?.heading}`}
                                                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                                  >
                                                                    {element?.heading}
                                                                  </Link>
                                                                </li>
                                                              );
                                                            })}
                                                          </ul>
                                                        </div>
                                                      }

                                                  </div>

                                                  {allSponsers?.some(sponsor => sponsor.sessionId === session.id) && (
                                                  <div class="sponsoredby-title"
                                                  style={{
                                                     marginLeft: '35%'}}
                                                  >Supported by:</div>
                                                  )}
                                                  <div class="date-and-time">
                                                    <div class="sponsor-logo-ruppe-icon">
                                                    <div>
                                                      <strong>
                                                        {allSponsers?.filter(sponsor => sponsor.sessionId === session.id)
                                                          .map((heading, idx) => {
                                                            console.log(heading)                      
                                                            return (
                                                              <div key={idx} className="sponsor-logo-img">
                                                                {heading.sponsors}
                                                              </div>
                                                              );
                                                            }
                                                        )}
                                                      </strong></div>
                                                    </div>
                                                  </div>                                                
                                              </div>   
                                          </div>
                                        )
                                    })}
                                  <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                    
                            </div>
                        
                            
                        </div>	
                    </div>
                    
                </div>
            </div>
        </div>

      <Footer />
    </div>
  );
};

export default SessionOldList;