// export default userService;
import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';
import { Link, useNavigate, useParams } from "react-router-dom";

const accessToken = userStore.getState().user.token;


const userService = {
   
    userlist: async (params = '') => {
        return apiRequest({
          method: "GET",
          url: `/user/user/list?${params}`,
          headers: {
            Authorization: "Bearer " + accessToken
          }
        });
      },

    speakerlist: async (params = '') => {
      return apiRequest({
        method: "GET",
        url: `/speakers/list-adminpanel?${params}`,
        headers: {
          Authorization: "Bearer " + accessToken
        }
      });
    },

    

    userexport: async (params = '') => {
      return apiRequest({
        method: "GET",
        url: `/user/exportusers?${params}`,
        headers: {
           Authorization: "Bearer " + accessToken
        }
      });
    },

  
    
    
    downloadExport: async (filename) => {
      return apiRequest({
        method: "GET",
        url: `/user/download/${filename}`,
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': "Bearer " + accessToken
        }
      });
    },

    delegatesexport: async (params = '') => {
      return apiRequest({
        method: "GET",
        url: `/user/exportdelegates?${params}`,
        headers: {
           Authorization: "Bearer " + accessToken
        }
      });
    },

  
    
    
    downloadExportdelegates: async (filename) => {
      return apiRequest({
        method: "GET",
        url: `/user/downloaddelegates/${filename}`,
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': "Bearer " + accessToken
        }
      });
    },

    studentsexport: async (params = '') => {
      return apiRequest({
        method: "GET",
        url: `/user/exportstudents?${params}`,
        headers: {
           Authorization: "Bearer " + accessToken
        }
      });
    },

  
    
    
    downloadExportstudents: async (filename) => {
      return apiRequest({
        method: "GET",
        url: `/user/downloadstudents/${filename}`,
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': "Bearer " + accessToken
        }
      });
    },

    speakerupdate: async (data) => {
      return apiRequest({
          method:"PUT",
          url: `/user/edit/${data.id}`,
          data:data,
          headers: {
              Authorization: "Bearer " + accessToken,
              'Content-Type': 'application/json'
          }
      });
  },

  adminlist: async (params = '') => {
    return apiRequest({
        method:"GET",
        url: `user/admin/list?${params}`,
        headers: {
            Authorization: "Bearer " + accessToken,
            'Content-Type': 'application/json'
        }
    });
},

delegatelist: async (params = '') => {
  return apiRequest({
      method:"GET",
      url: `user/delegate/list?${params}`,
      headers: {
          Authorization: "Bearer " + accessToken,
          'Content-Type': 'application/json'
      }
  });
},
 
  createuser: async (data) => {
    return apiRequest({
        method: "POST",
        url: `/user/create`,
        data:data,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
               
      });
  },

  speakerimageupload: async (data) => {
    return apiRequest({
        method: "POST",
        url: `/user/speaker/image`,
        data:data,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
        },
               
      });
  },

  sliderlisting: async (data) => {
    return apiRequest({
        method: "GET",
        url: `/slider/list`,
        data:data,
        headers: {
          Authorization: "Bearer " + accessToken,
          
        },
               
      });
  },

  slidercreate: async (data) => {
    return apiRequest({
        method: "POST",
        url: `/slider/image`,
        data:data,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
        },
               
      });
  },


  updateuser: async (data) => {
    return apiRequest({
        method: "PUT",
        url: `/user/edit/${data.id}`,
        data:data,
               
      });
  },

  // studentlist: async (data) => {
  //   return apiRequest({
  //     method: "GET",
  //     url: `user/student/list`,
  //     data:data,
  //     headers: {
  //       Authorization: "Bearer " + accessToken
  //     }
  //   });
  // },

  studentlist: async (params = '') => {
    return apiRequest({
      method: "GET",
      url: `/user/student/list?${params}`,
      headers: {
        Authorization: "Bearer " + accessToken
      }
    });
  },

  userCount: async (data) => {
    return apiRequest({
      method: "GET",
      url: `user/view/count`,
      data:data,
      headers: {
        Authorization: "Bearer " + accessToken
      }
    });
  },
          
  userview: async (uid) => {
    return apiRequest({
      method: "GET",
      url: `user/view/user?uId=${uid}`,
      
      headers: {
        Authorization: "Bearer " + accessToken
      }
    });
  },  
  
  userexportzoho: async (params = '') => {
    return apiRequest({
      method: "GET",
      url: `/user/exportuserszoho?${params}`,
      headers: {
         Authorization: "Bearer " + accessToken
      }
    });
  },


  
  
  downloadExportzoho: async (filename) => {
    return apiRequest({
      method: "GET",
      url: `/user/downloadzoho/${filename}`,
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': "Bearer " + accessToken
      }
    });
  },

  delegatesexportzoho: async (params = '') => {
    return apiRequest({
      method: "GET",
      url: `/user/exportdelegateszoho?${params}`,
      headers: {
         Authorization: "Bearer " + accessToken
      }
    });
  },


  
  
  downloadExportdelegateszoho: async (filename) => {
    return apiRequest({
      method: "GET",
      url: `/user/downloaddelegateszoho/${filename}`,
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': "Bearer " + accessToken
      }
    });
  },

  studentsexportzoho: async (params = '') => {
    return apiRequest({
      method: "GET",
      url: `/user/exportstudentszoho?${params}`,
      headers: {
         Authorization: "Bearer " + accessToken
      }
    });
  },


  
  
  downloadExportstudentszoho: async (filename) => {
    return apiRequest({
      method: "GET",
      url: `/user/downloadstudentszoho/${filename}`,
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Authorization': "Bearer " + accessToken
      }
    });
  },

  newslist: async () => {
    return apiRequest({
      method: "GET",
      url: `/news/list`,
      headers: {
        Authorization: "Bearer " + accessToken
      }
    });
  },

  createnews: async (data) => {
    return apiRequest({
      method: "POST",
      url: `/news/create`,
      data:data,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type":  "multipart/form-data"
      }
    });
  },

  newsupdate: async (data) => {
    return apiRequest({
        method:"PUT",
        url: `/news/edit/${data.id}`,
        data:data,
        headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type":  "multipart/form-data"
        }
    });
},



videoslist: async (params = '') => {
  return apiRequest({
    method: "GET",
    url: `/videos/list?${params}`,
    headers: {
      Authorization: "Bearer " + accessToken
    }
  });
},

createvideos: async (data) => {
  return apiRequest({
    method: "POST",
    url: `/videos/create`,
    data:data,
    headers: {
      Authorization: "Bearer " + accessToken,
     
    }
  });
},

videosupdate: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/videos/edit/${data.id}`,
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
         
      }
  });
},

photogallerylist: async (params = '') => {
  return apiRequest({
    method: "GET",
    url: `/photogallery/list?${params}`,
    headers: {
      Authorization: "Bearer " + accessToken,
      
    }
  });
},

createphotogallery: async (data) => {
  return apiRequest({
    method: "POST",
    url: `/photogallery/create`,
    data:data,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type":  "multipart/form-data"
     
    }
  });
},

photogalleryupdate: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/photogallery/edit/${data.id}`,
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
         
      }
  });
},

photogalleryDelete: async (id) => {
  return apiRequest({
      method:"DELETE",
      url: "/photogallery/delete/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

videosDelete: async (id) => {
  return apiRequest({
      method:"DELETE",
      url: "/videos/delete/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

newsDelete: async (id) => {
  return apiRequest({
      method:"DELETE",
      url: "/news/delete/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

publishspeaker: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/user/publishspeaker/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

publishnews: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/news/publishnews/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

publishvideo: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/videos/publishvideos/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

publishphotogallery: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/photogallery/publishphotogallery/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

listspeakersite: async ({ page, size }) => {
  return apiRequest({
      method:"GET",
      url: `/user/listspeakers/site?page=${page}&size=${size}`,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},



listnewssite: async ({ page, size }) => {
  return apiRequest({
      method:"GET",
      url: `/news/list/news?page=${page}&size=${size}`,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},



listvideossite: async ({ page, size }) => {
  return apiRequest({
      method:"GET",
      url: `/videos/list/videos?page=${page}&size=${size}`,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},



listphotogallerysite: async ({ page, size }) => {
  return apiRequest({
      method:"GET",
      url: `/photogallery/list/photogallery?page=${page}&size=${size}`,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

speakersDelete: async (id) => {
  return apiRequest({
      method:"PUT",
      url: `/speakers/delete?id=${id}`, 
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

createspeaker: async (data) => {
  return apiRequest({
      method: "POST",
      url: `/speakers/create`,
      data:data,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
             
    });
},

updatespeaker: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/speakers/data-update?id=${data.id}`, 
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
          'Content-Type': 'application/json'
      }
  });
},

testimonialslist: async () => {
  return apiRequest({
    method: "GET",
    url: `/testimonials/list`,
    headers: {
      Authorization: "Bearer " + accessToken
    }
  });
},

createtestimonials: async (data) => {
  return apiRequest({
    method: "POST",
    url: `/testimonials/create`,
    data:data,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type":  "multipart/form-data"
    }
  });
},

testimonialsupdate: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/testimonials/edit/${data.id}`,
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
      }
  });
},

publishtestimonials: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/testimonials/publishtestimonials/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

testimonialsDelete: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/testimonials/delete/" + id, 
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

publishslider: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/slider/publishslider/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

deleteslider: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/slider/delete/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},




updateslider: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/slider/edit/${data.id}`,
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
         
      }
  });
},

discountcouponlist: async () => {
  return apiRequest({
    method: "GET",
    url: `/discountcoupon/list`,
    headers: {
      Authorization: "Bearer " + accessToken,
      
    }
  });
},

creatediscountcoupon: async (data) => {
  return apiRequest({
    method: "POST",
    url: `/discountcoupon/create`,
    data:data,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type":  "multipart/form-data"
     
    }
  });
},

discountcouponupdate: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/discountcoupon/edit/${data.id}`,
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
         
      }
  });
},

discountcouponDelete: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/discountcoupon/delete/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},
discountcouponActivateordeactivate: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/discountcoupon/activateordeactivate/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},


partnerlogolist: async (params = '') => {
  return apiRequest({
    method: "GET",
    url: `/logo/list?${params}`,
    headers: {
      Authorization: "Bearer " + accessToken,
      
    }
  });
},

createpartnerlogo: async (data) => {
  return apiRequest({
    method: "POST",
    url: `/logo/create`,
    data:data,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type":  "multipart/form-data"
     
    }
  });
},

partnerlogoupdate: async (data) => {
  return apiRequest({
      method:"PUT",
      url: `/logo/edit/${data.id}`,
      data:data,
      headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type":  "multipart/form-data"
         
      }
  });
},

partnerlogoDelete: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/logo/delete/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

publishpartnerlogo: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/logo/publishlogo/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

logohierarchy: async () => {
  return apiRequest({
      method:"GET",
      url: "/logo/logohierarchy",
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},
featuredspeaker: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/speakers/featuredspeaker/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

featurednews: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/news/featurednews/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

featuredspeakeradd: async (data) => {
  return apiRequest({
      method:"POST",
      url: "/speakers/addfeaturedspeaker",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

featurednewsadd: async (data) => {
  return apiRequest({
      method:"POST",
      url: "/news/addfeaturednews",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},
featuredvideos: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/videos/removefeaturedvideos/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

featuredphotogallery: async (id) => {
  return apiRequest({
      method:"PUT",
      url: "/photogallery/removefeaturedphotogallery/" + id,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

featuredvideosadd: async (data) => {
  return apiRequest({
      method:"POST",
      url: "/videos/addfeaturedvideos",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},

featuredphotogalleryadd: async (data) => {
  return apiRequest({
      method:"POST",
      url: "/photogallery/addfeaturedphotogallery",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},
listpublishednews: async (data) => {
  return apiRequest({
      method:"GET",
      url: "/news/list-publishednews",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},


listpublishedspeakers: async (data) => {
  return apiRequest({
      method:"GET",
      url: "/speakers/list-publishedspeakers",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},
listpublishedphotogallery: async (data) => {
  return apiRequest({
      method:"GET",
      url: "/photogallery/list-publishedphotogallery",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},


listpublishedvideos: async (data) => {
  return apiRequest({
      method:"GET",
      url: "/videos/list-publishedvideos",
      data: data,
      headers: {
          Authorization: "Bearer " + accessToken
      }
  });
},
        
}

export default userService;