import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import multicolor from '../images/multicolor-hline.jpg'
import Navbar from './Navbar';
import Footer from './Footer';
import moment from 'moment/moment';
import gmapicon from '../images/gmap-icon.png'
import { useDispatch, useSelector } from 'react-redux';
import { resetData, setActiveTab, setAllSessions, setAllSpeakers, setAllSponsers, setAllVenues, setSearch, setSelectedCategory, setSelectedDelegate, setSelectedLanguage, setSelectedSpeaker, setSelectedVenue } from '../redux/reducers/sessionReducers';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import SessionFavModal from './SessionFavModal';
import Chatbot from './chatbot/Chatbot';
import { Checkbox } from '@nextui-org/react';
import SessionShareModal from './SessionShareModal';

const SessionList = () => {

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false);

  const allSessions = useSelector((state)=> state.sessions.allSessions)
  const allSpeakers = useSelector((state)=> state.sessions.allSpeakers)
  const allSponsers = useSelector((state)=> state.sessions.allSponsers)
  const allVenues = useSelector((state)=> state.sessions.allVenues)
  const selectedSpeaker = useSelector((state)=> state.sessions.selectedSpeaker)
  const selectedVenue = useSelector((state)=> state.sessions.selectedVenue)
  const activeTab = useSelector((state)=> state.sessions.activeTab)
  const search = useSelector((state)=> state.sessions.search)
  const selectedCategory = useSelector((state)=> state.sessions.selectedCategory)
  const selectedLanguage = useSelector((state)=> state.sessions.selectedLanguage)
  const selectedDelegate = useSelector((state)=> state.sessions.selectedDelegate)

  const [sessions, setSessions] = useState([]);
  const [sort, setSort] = useState('asc');

  const [isLoading, setIsLoading] = useState(true);

  const [speakerFilter, setSpeakerFilter] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const boxRefs = useRef({});
  const [maxContentHeight, setMaxContentHeight] = useState(0);

  const handleOpen = () => setIsOpen(true);
        
  const getSessionData = async () => {
    dispatch(resetData())
    try{
      const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/sessions.json");
      const text = await response.text();
      const data = await JSON.parse(text);
      dispatch(setAllSessions(data))
      const sortData = sortSessionData(data);
      let filteredData = sortData
      if(activeTab){
        filteredData = filteredData?.filter((session) =>
          moment(session?.date).format('MMM D') === activeTab
        )
      }
      setSessions(filteredData);
      setIsLoading(false);
    }catch(error){
      console.log("Error fetching data:", error.message);
      setIsLoading(false);
    }
  };

  const sortSessionData = (sessions) => {

    return [...sessions].sort((a, b) => {

      const dateA = a?.date?.split('T')[0];
      const dateB = b?.date?.split('T')[0];
      
      let dateComparison = new Date(dateA) - new Date(dateB);
      
      if (dateComparison === 0) {
        const timeA = convertTimeToMinutes(a?.time_from);
        const timeB = convertTimeToMinutes(b?.time_from);
        dateComparison = timeA - timeB;
      }
      
      return sort === 'desc' ? -dateComparison : dateComparison;

    });
  };
  
  const convertTimeToMinutes = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return (hours * 60) + minutes;
  };
  
  const getSpeakerData = async () => {
    try{
      const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/speakers.json");
      const text = await response.text();
      const data = JSON.parse(text);
      dispatch(setAllSpeakers(data))
    }catch(error){
      console.error("Error fetching data:", error);
    }
  };

  const getVenueData = async () => {
    try{
      const response = await fetch("https://cf-cdn.keralaliteraturefestival.com/venue.json");
      const text = await response.text();
      const data = JSON.parse(text);
      dispatch(setAllVenues(data))
    }catch(error){
      console.error("Error fetching data:", error);
    }
  };

  const getSessionSponsers = async () => {
    try{
      const session_sponsors = await fetch("https://cf-cdn.keralaliteraturefestival.com/session_sponsor.json");
      const sponsors = await fetch("https://cf-cdn.keralaliteraturefestival.com/sponsor.json");
      const sessionSponsorsText = await session_sponsors.text();
      const sponsorsText = await sponsors.text();
      const sessionSponsorsData = JSON.parse(sessionSponsorsText);
      const spornsorsData = JSON.parse(sponsorsText);
      const sessionData = sessionSponsorsData?.map(sesspo => {
        const sessionSponsor = spornsorsData?.find(sponsor => sesspo?.sponsorId === sponsor?.id);
        return {
          ...sesspo,
          sponsors: sessionSponsor?.name || ''
        };
      })
      dispatch(setAllSponsers(sessionData))
    }catch(error){
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSessionData();
  }, []);

  useEffect(() => {
    getSpeakerData();
    getVenueData()
    getSessionSponsers()
  }, []);


  const findUniqueDates = () => {
    const sessionDates = allSessions
    .filter(session => session.date !== null)
    .map(session => moment(session.date).format('YYYY-MM-DD'));
    const uniqueDates = sessionDates.filter((date, index) => sessionDates.indexOf(date) === index);
    const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
    const formattedDates = sortedDates.map(date => moment(date).format('MMM D'));
    return formattedDates
  }; 

  const uniqueDates = findUniqueDates()

  const findUniqueCategories = () => {
    const sessionCategories = allSessions
    .filter(session => session?.category !== null)
    .map(session => session?.category);
    const uniqueCategories = sessionCategories?.filter((category, index) => sessionCategories?.findIndex(cat => cat?.id === category?.id) === index);
    return uniqueCategories;
  };

  const uniqueCategories = findUniqueCategories()

  const findUniqueVenue = () => {
    const ve = sessions.
    filter(session => session?.venue?.id != null)
    .map(session => session?.venue)
    const uniqueVenue = ve?.filter((venues, index) => ve?.findIndex(cat => cat?.id === venues?.id) === index);
    return uniqueVenue
  };

  const uniqueVenue = findUniqueVenue()

  const handleSpeakerSearch = (e) => {
    if(e.target.value){
      setSpeakerFilter(e.target.value)
    }else{
      handleSpeakerFilter("")
    }
  };


  const handleSpeakerFilter = (id) => {
    dispatch(setSelectedSpeaker(id))
    const speaker = allSpeakers.find((speaker) => speaker.id === id);
    if(speaker){
      setSpeakerFilter(speaker.heading);
    }else{
      setSpeakerFilter('');
    }
    setIsDropdownOpen(false);
  };

  const handleVenueFilter = (e) => {
    dispatch(setSelectedVenue(e.target.value))
    dispatch(setActiveTab(""))
  };

  const handleTabClick = (formattedDate) => {
    dispatch(setActiveTab(formattedDate))
  };

  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value))
    dispatch(setActiveTab(""))
  };

  const handleCategory = (e) => {
    dispatch(setSelectedCategory(e.target.value))
    dispatch(setActiveTab(""))
  };

  const handleLanguage = (e) => {
    dispatch(setSelectedLanguage(e.target.value))
    dispatch(setActiveTab(""))
  };

  const handleDelegate = (e) => {
    if(e.target.checked){
      dispatch(setSelectedDelegate("YES"))
      dispatch(setActiveTab(""))
    }else{
      dispatch(setSelectedDelegate(""))
    }
  };


  useEffect(() => {
    let filteredData = allSessions
    if(selectedSpeaker){
      dispatch(setActiveTab(''))
      filteredData = filteredData?.filter((session) =>
        session?.session_speackers?.some((element) => element.speaker.id === selectedSpeaker) ||
        session?.session_moderators?.some((element) => element.speaker.id === selectedSpeaker) ||
        session?.speakers?.some((element) => element.id === selectedSpeaker)
      );
    }
    if(search){
      dispatch(setActiveTab(''))
      filteredData = filteredData?.filter((session) =>
        session?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }
    if(selectedVenue){
      dispatch(setActiveTab(''))
      filteredData = filteredData?.filter((session) =>
        session?.venue?.id === Number(selectedVenue)
      );
    }
    if(selectedCategory){
      dispatch(setActiveTab(''))
      filteredData = filteredData?.filter((session) =>
        session?.category?.id === Number(selectedCategory)
      )
    }
    if(selectedLanguage){
      dispatch(setActiveTab(''))
      filteredData = filteredData?.filter((session) =>
        session?.language === selectedLanguage
      )
    }
    if(activeTab){
      filteredData = filteredData?.filter((session) =>
        moment(session?.date).format('MMM D') === activeTab
      )
    }
    if(selectedDelegate){
      dispatch(setActiveTab(''))
      filteredData = filteredData?.filter((session) =>
        session?.delegative_exclusive === selectedDelegate
      )
    }
    const sortData = sortSessionData(filteredData);
    setSessions(sortData)
  }, [selectedSpeaker, search, selectedVenue, activeTab, selectedCategory, selectedLanguage, selectedDelegate, sort]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      #root, .App {
        height: 100%;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const calculateMaxContentHeight = () => {
      let maxHeight = 0;
      uniqueVenue?.forEach((venue, index) => {
        const venueSessions = sessions?.filter(item => item?.venue?.id === venue?.id);
        const boxRef = boxRefs.current[`venue-${index}`];
        if (boxRef) {
          const totalContentHeight = venueSessions?.reduce((acc, session) => {
            return acc + 200;
          }, 0);
          maxHeight = Math.max(maxHeight, totalContentHeight);
        }
      });
      setMaxContentHeight(maxHeight);
    };

    calculateMaxContentHeight();
    window.addEventListener('resize', calculateMaxContentHeight);
    return () => window.removeEventListener('resize', calculateMaxContentHeight);
  }, [uniqueVenue, sessions]);

  useEffect(() => {
    const handleScroll = (sourceId, scrollTop) => {
      Object.entries(boxRefs.current).forEach(([id, ref]) => {
        if (id !== sourceId && ref) {
          ref.scrollTop = scrollTop;
        }
      });
    };

    Object.entries(boxRefs.current).forEach(([id, ref]) => {
      if (ref) {
        ref.onscroll = (e) => handleScroll(id, e.target.scrollTop);
      }
    });

    return () => {
      Object.values(boxRefs.current).forEach(ref => {
        if (ref) {
          ref.onscroll = null;
        }
      });
    };
  }, [uniqueVenue]);
  
  const cardColors = ['yellow', 'blue', 'red', 'green'];
  
  const formatSessionDateTime = (session, index) => {
    if (!session.date || !session.time_from || !session.time_to) return null;
    
    const day = moment(session.date).format('MMM DD');
    const color = cardColors[index % cardColors.length];
    
    const timeFrom = moment(session.time_from, 'HH:mm:ss').format('h:mm A');
    const timeTo = moment(session.time_to, 'HH:mm:ss').format('h:mm A');
    return {
      day,
      color,
      timeRange: `${timeFrom}-${timeTo}`
    };
  };

  if (isLoading) {
    return <div>Loading sessions...</div>;
  }

  return (
    <Fragment>

      <Navbar handleOpen={handleOpen} />

      <div className="innerbanner container-fluid session-banner">
          <div className="container">
              <div className="row">
                  <div className="col-md-12" style={{position:'relative'}}>
                      <span className="logo-strips"></span> <h2>Programme</h2>
                  </div>
              </div>
          </div>
      </div>

      <div className="sessions-tabs container">
        <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <ul className="nav nav-tabs">
                {uniqueDates.map((formattedDate, index) => {
                  const tabColors = ['#f9cd26', '#e13231', 'green', '#4aa6df', '#ec842c'];
                  return (
                    <li key={formattedDate} className="nav-item">
                      <a
                      className={`nav-link ${activeTab === formattedDate ? 'active' : ''}`}
                      onClick={() => handleTabClick(formattedDate)}
                      href="#"
                      style={{
                        color: activeTab === formattedDate 
                          ? tabColors[index % tabColors.length] 
                          : ''
                      }}
                      >
                        {formattedDate}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
        </div>
      </div>

      <div className={`container filter-search-wrpr filter-pos`}>
        <div className="row">

          <div className="col-md-12 filter-wrpr session-filter-wrpr">
            <div className="row">
                <div className="col-md-2 mb-3">
                    <label>Venue</label>
                    <select 
                    className="form-control form-control-sm"
                    value={selectedVenue || ''}
                    onChange={handleVenueFilter}
                    >
                      <div className="dropdown-search-wrapper" />
                        <option value="">All Venues</option>
                        {allVenues.map((venue) => (
                          <option key={venue.id} value={venue.id}>
                            {venue?.name} {venue?.number && `- (${venue?.number})`}
                          </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-2 mb-3">
                  <label>Event Type</label>
                  <div className="dropdown">
                    <select 
                    className="form-control form-control-sm select2"
                    value={selectedCategory || ''}
                    onChange={handleCategory}
                    >
                      <option value="">All Event</option>
                      {uniqueCategories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-2 mb-3">
                  <label> Programme Language</label>
                  <div className="dropdown">
                    <select 
                    className="form-control form-control-sm select2"
                    value={selectedLanguage || ''}
                    onChange={handleLanguage}
                    >
                      <option value="">All Languages</option>
                      {["Malayalam", "English"].map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className="col-md-2 mb-3">
                  <label> Delegate</label>
                  <div className="dropdown">
                    <select 
                    className="form-control form-control-sm select2"
                    value={selectedDelegate || ''}
                    onChange={handleDelegate}
                    >
                      <option value="">All Delegates</option>
                      <option value="YES">Delegate Only</option>
                    </select>
                  </div>
                </div> */}

                <div className="col-md-2 mb-3">
                  <label> Delegate</label>
                  <div className="dropdown" style={{height: "100%", display: "flex", alignItems: "center", gap: "10px"}}>
                    {/* <select 
                    className="form-control form-control-sm select2"
                    value={selectedDelegate || ''}
                    onChange={handleDelegate}
                    >
                      <option value="">All Delegates</option>
                      <option value="YES">Delegate Only</option>
                    </select> */}
                    <input value={selectedDelegate} onChange={handleDelegate} type="checkbox" style={{height: "20px", width:"20px"}} />Delegate only
                  </div>
                </div>

                <div className="col-md-2 mb-3">
                  <label>Speakers</label>
                  <div className="dropdown">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search speakers..."
                    value={speakerFilter}
                    onChange={handleSpeakerSearch}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDropdownOpen(true);
                    }}
                  />
                    {isDropdownOpen && (
                      <div
                      className="dropdown-menu show w-100"
                      style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        padding: '10px',
                        display: 'block',
                        position: 'absolute',
                        zIndex: 1000
                      }}
                      >
                        <button className={`dropdown-item hover-blue ${selectedSpeaker === '' ? 'active' : ''}`} onClick={() => handleSpeakerFilter('')}>
                          All Speakers
                        </button>

                        {allSpeakers.filter((speaker) =>
                          speaker.heading.toLowerCase().includes(speakerFilter.toLowerCase())
                        ).map((speaker) => (
                            <button
                            key={speaker.id}
                            className={`dropdown-item hover-blue ${selectedSpeaker === speaker.heading ? 'active' : ''}`}
                            onClick={() => {
                              handleSpeakerFilter(speaker.id);
                            }}
                            >
                              {speaker.heading}
                            </button>
                          ))
                        }

                        {allSpeakers?.filter((speaker) =>
                          speaker?.heading?.toLowerCase().includes(speakerFilter.toLowerCase())
                        ).length === 0 && (
                          <div className="dropdown-item text-muted">
                            No speakers found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>


                <div className="col-md-2 mb-3">
                  <div className="search-wrpr">
                    <svg className="search-icon-svg" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.164 10.133L16 14.97 14.969 16l-4.836-4.836a6.225 6.225 0 01-3.875 1.352 6.24 6.24 0 01-4.427-1.832A6.272 6.272 0 010 6.258 6.24 6.24 0 011.831 1.83 6.272 6.272 0 016.258 0c1.67 0 3.235.658 4.426 1.831a6.272 6.272 0 011.832 4.427c0 1.422-.48 2.773-1.352 3.875zM6.258 1.458c-1.28 0-2.49.498-3.396 1.404-1.866 1.867-1.866 4.925 0 6.791a4.774 4.774 0 003.396 1.405c1.28 0 2.489-.498 3.395-1.405 1.867-1.866 1.867-4.924 0-6.79a4.774 4.774 0 00-3.395-1.405z"></path></svg>
                    
                    <div className="input-group">
                      <input 
                      type="text"
                      className="form-control form-control-sm" 
                      placeholder="Search programme..."
                      value={search}
                      onChange={handleSearch}
                      />
                      </div>
                    
                  </div>
                </div>

            </div>
          </div>

          <div className="col-md-12">
            <img className="multicolorline" style={{marginTop: 0}} src={multicolor}  />
          </div>

        </div>
      </div>

      <div className=" container calc-height">

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: "10px", position:"absolute", right:"15px", zIndex:"100"}}>
          <div className="prev-page" style={{display: 'flex', flexDirection: 'row', alignItems:'center', cursor:'pointer'}}>
            <ChevronLeft color="#00f" size={22}/>
            <div style={{display:'flex', flexDirection: 'column', fontSize: "10px", lineHeight: "9px", color:'#d83c34', fontWeight: "600", zIndex:"100"}}>
              <span>PREV</span>
              <span>VENUE</span>
            </div>
          </div>
          <div className="next-page" style={{display: 'flex', flexDirection: 'row', alignItems:'center', cursor:'pointer', zIndex:"100"}}>
            <div style={{display:'flex', flexDirection: 'column', textAlign: 'right', fontSize: "10px", lineHeight: "9px", color:'#d83c34', fontWeight: "600", zIndex:"100"}}>
              <span>NEXT</span>
              <span>VENUE</span>
            </div>
            <ChevronRight color="#00f" size={22}/>
          </div>
        </div>

        <div className="row h-100" style={{ position: "relative" }}>
          <div className="col-md-12 h-100">

          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            loop={false}
            speed={500}
            slidesPerView={4}
            autoplay={{
              delay: 50000,
              disableOnInteraction: false,
            }}
            pagination={{
              el: '.swiper-pagination.speakers-pagination',
              clickable: true,
            }}
            navigation={{
              nextEl: '.next-page',
              prevEl: '.prev-page',
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 2  
                },
              500: {       
                slidesPerView: 1,
                spaceBetween: 2     
              },
              800: {       
                slidesPerView: 2,
                spaceBetween: 2     
              },
              1000: {       
                slidesPerView: 3,
                spaceBetween: 2     
              }
            }}
            loopedSlides={4}
            className="swiper-container sessions-new" 
            style={{borderRadius:'0px'}}
          >
            {uniqueVenue?.sort((a, b) => a.number - b.number).map((venue, index) => {
              const venueSessions = sessions?.filter?.(item => item?.venue?.id === venue?.id)
              return(
              <SwiperSlide key={index}>
                <div className="swiper-slide">
                  <div className="session-col">
                    <div className="session-col-child ">
                      <div className="session-card">
                        <div className="sessionlist-header">
                          <div className="symbol-venue">
                            <div className="venue">
                              <div
                                style={{
                                  backgroundColor: "#eecf40",
                                  color: "#000",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "normal",
                                  height: 18,
                                  width: 18,
                                  borderRadius: "50%",
                                  marginRight: 10
                                }}
                              >
                                {venue?.number}
                              </div>
                              <div className="venue-eng">{venue?.name}</div>
                              <div style={{marginLeft: "10px", fontSize: "12px", color: "#d83c34", fontWeight: "600"}}>
                                {venue?.number}/{allVenues?.length}
                              </div>
                              <a href={venue?.place} class="icon-location-custom" target="_blank" title="Venue Location"><img src={gmapicon} /></a>
                            </div>
                          </div>
                        </div>
                        <div className="session-card-body box"
                        ref={el => boxRefs.current[`venue-${index}`] = el}
                       >
                          {
                            venueSessions?.map((session, index) => {
                              return(
                              <div className="card session-card">
                                <div className="card-body">
                                  <div className="fav-wrpr">
                                    <SessionFavModal/>
                                  </div>
                                  <SessionShareModal/>
                                  <div className="date-time">
                                    <div className="date yellow">
                                      {(() => {
                                        const dateTime = formatSessionDateTime(session, index);
                                        return dateTime && (
                                            <span>
                                              {dateTime.day}
                                            </span>
                                        );
                                      })()}
                                    </div>
                                    <div className="time">
                                      <i className="icon-time1" />
                                      {(() => {
                                        const dateTime = formatSessionDateTime(session, index);
                                        return dateTime && (
                                            <span>
                                              {dateTime.timeRange}
                                            </span>
                                        );
                                      })()}
                                    </div>
                                    {session?.delegative_exclusive === "YES" && (
                                      <div className="delegate-only">Delegate Only</div>
                                    )}
                                  </div>
                                  <div className="scard-heading">
                                    <h2>
                                      {session?.name}
                                    </h2>
                                  </div>
                                  <div className="session-type category-wrpr">
                                    <div className="session-type-label">{session?.category?.name}</div>
                                  </div>


                                    {session?.session_speackers?.length > 0 && (
                                      <div className="session-members speakers">
                                        <ul>
                                          {session?.session_speackers
                                            ?.slice()
                                            ?.sort((a, b) => a.id - b.id)
                                            ?.map((element) => {
                                              const speaker = allSpeakers?.find(speaker => speaker.id === element.speaker.id);
                                              if (!speaker) return null;                                                                                                
                                              return (
                                                <li key={speaker.id}>
                                                    <Link
                                                    to={`/speakerview/${element.speaker.id}/${speaker.heading}`}
                                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                    className="cursor-pointer decoration-none"
                                                    >
                                                    {speaker.heading}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    )}

                                  
                                    {session?.session_moderators?.length > 0 &&
                                      <div className="session-members modarators">
                                        <ul>
                                          {session?.session_moderators?.map((element, idx) => {
                                            const speaker = allSpeakers?.find(speaker => speaker.id === element.speaker.id);
                                            if (!speaker) return null;
                                            return (
                                              <li key={idx} className="mb-1">
                                                <Link
                                                  to={`/speakerview/${element.speaker.id}/${speaker.heading}`}
                                                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                >
                                                  {speaker?.heading}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    }
    
                                    {session?.speakers?.length > 0 &&
                                      <div className="session-members storyteller">
                                        <ul>
                                          {session?.speakers?.map((element, idx) => {
                                            return (
                                              <li key={idx} className="mb-1">
                                                <Link
                                                  to={`/speakerview/${element?.id}/${element?.heading}`}
                                                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                >
                                                  {element?.heading}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    }

                                </div>

                                
                                {
                                  allSponsers
                                  ?.filter(sponsor => sponsor.sessionId === session.id).length > 0 &&
                                  <div className="sponsoredby-title" style={{ textAlign: 'center' }}>Supported by:</div>
                                }
                                <div className="date-and-time">
                                  <div className="sponsor-logo-ruppe-icon">
                                    <strong>
                                      {allSponsers
                                        ?.filter(sponsor => sponsor.sessionId === session.id)
                                        .map((heading, idx, array) => (
                                          <div key={idx} className="sponsor-logo-img" style={{ textAlign: 'center' }}>
                                            {heading.sponsors}
                                            {idx < array.length - 1 && ', '}
                                          </div>
                                        ))}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            )})
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              )}
            )}
          </Swiper>

          </div>
        </div>
      </div>

      <Footer />

    </Fragment>
  );
};

export default SessionList;